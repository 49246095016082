import type { DataFunctionArgs, MetaFunction } from '@remix-run/cloudflare'
import { useEffect, useState } from 'react'
import { css } from 'styled-system/css'
import isUnder1651iOS from '~/utils/isUnder1651iOS'

export const meta: MetaFunction = () => {
  return [{ title: 'photo-sta::link' }, { name: 'description', content: 'link for kiosk' }]
}

export const loader = async ({ context }: DataFunctionArgs) => {
  // api test
  const client = context.env.RELAY_CLIENT
  const res = await client.hello.$get()
  const resJson = await res.json()
  console.log(`GET RES -> ${JSON.stringify(resJson)}`)

  const resPost = await client.hello.$post()
  const resPostJson = await resPost.json()
  console.log(`POST RES -> ${JSON.stringify(resPostJson)}`)
  return {}
}

export default function RouteIndex() {
  const [userAgent, setUserAgent] = useState<string | undefined>(undefined)
  const [isOldiOS, setIsOldiOS] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    setUserAgent(navigator.userAgent)
    setIsOldiOS(isUnder1651iOS())
  }, [])

  return (
    <main
      className={css({
        width: 'container',
        maxWidth: 'container',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      })}
    >
      <h1
        className={css({
          margin: '10',
          userSelect: 'none',
          fontSize: '3xl',
          lineHeight: '3xl',
          fontWeight: 'bold',
        })}
      >
        photo-sta::link
      </h1>
      <div className={css({ width: 'container', maxWidth: 'container', padding: '4' })}>
        <div>{`UserAgent: ${userAgent}`}</div>
        <div>{`isOldiOS: ${isOldiOS}`}</div>
      </div>
    </main>
  )
}
